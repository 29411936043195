import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {Fragment} from "react";

function ConfirmDialog(props) {
    const {open, onClose, onOk, title, message, cancelExist} = props;

    const onClickOk = () => {
        if (onOk) onOk();
    }
    const onClickCancel = () => {
        if (onClose) onClose();
    }

    return <Dialog open={open}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {Array.isArray(message) ? message.map((m, idx) => (<Fragment key={idx}><span>{m}</span><br /></Fragment>)) : message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {cancelExist && <Button onClick={onClickCancel}>취소</Button>}
            <Button onClick={onClickOk}>확인</Button>
        </DialogActions>
    </Dialog>
}

export default ConfirmDialog;