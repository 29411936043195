import Layout from "./pages/Layout";
import {createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider} from "react-router-dom";
import Login from "./pages/Login";
import {QueryCache, QueryClient, QueryClientProvider} from "@tanstack/react-query";
import WaggleCandidates from "./pages/WaggleCandidates";
import WagglePersonView from "./pages/WagglePersonView";
import {batTheme} from "./components/common/theme";
import {ThemeProvider} from "@mui/system";
import {GoogleOAuthProvider} from "@react-oauth/google";
import FeedbackSnackbar from "./components/common/FeedbackSnackbar";
import {useSetAtom} from "jotai";
import {feedbackSnackbarAtom} from "./store/atom";
import {buildErrorMessage} from "./util/misc";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';
import {Helmet, HelmetProvider} from "react-helmet-async";

const router = createBrowserRouter(createRoutesFromElements(
    <>
        <Route index={true} element={<Navigate to={"/waggle/candidates"} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/waggle" element={<Layout />}>
            <Route path="candidates" element={<Outlet />}>
                <Route index element={<WaggleCandidates />} />
                <Route path=":candidateId" element={<WagglePersonView />} />
            </Route>
        </Route>

    </>
));

dayjs.extend(utc);
dayjs.extend(dayjsCustomParseFormat);

function App() {
    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    // https://velog.io/@cnsrn1874/breaking-react-querys-api-on-purpose
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: 0,
                refetchOnWindowFocus: false
            }
        },
        queryCache: new QueryCache({
            onError: (err, query) => {
                if (! query.meta?.silent) {
                    if (query.meta?.errorMessage) {
                        setFeedbackSnackbar([query.meta.errorMessage, 'error']);
                    } else {
                        setFeedbackSnackbar([
                            buildErrorMessage(err, query.meta?.errorMessages||{}), 'error']);
                    }
                }
            },
            onSuccess: (data, query) => {
                if (query.meta?.successMessage) {
                    setFeedbackSnackbar([query.meta.successMessage, 'info']);
                }
            }
        })
    });

    const clientId = process.env.REACT_APP_BAT_WEB_OAUTH_CLIENT_ID;

    if (process.env.REACT_APP_STAGE === 'prod') {
        const noOp = function(){}; // no-op function
        if (window.console) { window.console.log = noOp; }
    }

    return (
        <ThemeProvider theme={batTheme}>
            <HelmetProvider>
                <GoogleOAuthProvider clientId={clientId}>
                    <QueryClientProvider client={queryClient}>
                        <div className="App">
                            <Helmet><title>BAT-Web | BEES</title></Helmet>
                            <RouterProvider router={router} />
                            <FeedbackSnackbar />
                        </div>
                    </QueryClientProvider>
                </GoogleOAuthProvider>
            </HelmetProvider>
        </ThemeProvider>
  );
}

export default App;
