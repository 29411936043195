import {AgGridReact} from 'ag-grid-react';
import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Card, CardContent, Grid, IconButton, Typography} from "@mui/material";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import '../../css/agGridCustom.css';
import '../../css/DataGridAg.css';
import HCTableEditDialog from "./HCTableEditDialog";
import NoDataDiv from "./NoDataDiv";
import dayjs from "dayjs";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {feedbackSnackbarAtom} from "../../store/atom";
import {buildErrorMessage} from "../../util/misc";
import {useSetAtom} from "jotai";
import ConfirmDialog from "../common/ConfirmDialog";

/*
Column Type:
- string
- text
- date
- date_period_yyyymm
- url
- defined
- select
- multiselect
- free_solo
 */

/**
 * DataGridAg
 * @param queryKey - query key
 * @param queryFn - query function
 * @param queryEnabled - query enabled value
 * @param upsertMutateFn - upsert mutation function
 * @param deleteMutateFn - delete mutation function
 * @param columnDefs - column definitions
 * @param defaultValue - default value
 * @param config - configuration
 * @returns {React.JSX.Element|null}
 * @constructor
 */
/*
- config.viewGridWidth: sx 기준 grid 값 (전체 width, default: 12)
- config.getRowId: getRowId function (default: id)
- config.addButtonTitle: 추가 버튼 타이틀 (default: 추가하기)
- config.addDialogTitle: 추가 다이얼로그 타이틀 (default: '')
- config.readOnly: 읽기 전용 여부 (default: false)
- config.notEditable: 수정 불가능 여부 (true이거나 함수값(param=node.data)이 true이면 수정 불가능)
 */

const sx = {
    noteBox: {marginBottom: 2},
    noteTitle: {fontSize: '1em', fontWeight: 'bold'},
    noteContents: {marginTop: 1, fontSize: '0.8125rem', color: '#666'},
}


function DataGridAg({
    queryKey, queryFn, queryEnabled,
    upsertMutateFn, deleteMutateFn,
    columnDefs, defaultValue, config,
}) {
    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    const queryClient = useQueryClient();
    const {isSuccess, data} = useQuery({
        queryKey: queryKey,
        queryFn: queryFn,
        enabled: queryEnabled,
    });

    // config
    const viewGridWidth = config?.viewGridWidth || 12;
    const getRowId = config?.getRowId || ((params) => params.data.id);
    const getNote = config?.getNote;
    const addButtonTitle = config?.addButtonTitle || '추가하기';
    const addDialogTitle = config?.addDialogTitle || '';
    const readOnly = config?.readOnly || false;
    const notEditable = config?.notEditable || false;
    const onFetched = config?.onFetched;

    useEffect(() => {
        if (onFetched && data) {
            onFetched(data);
        }
    }, [data]);

    // data를 보고 api에서 insert인지 update인지 확인이 가능해야 함
    const upsertMutation = useMutation({
        mutationFn: upsertMutateFn,
        onSuccess: (resData, variables, context) => {
            console.log('onSaveSuccess', data, variables, context);
            queryClient.invalidateQueries({queryKey}).then(r => {});
        },
        onError: (error, variables, context) => {
            setFeedbackSnackbar([buildErrorMessage(error), 'error']);
        }
    });

    const deleteMutation = useMutation({
        mutationFn: deleteMutateFn,
        onSuccess: (resData, variables, context) => {
            console.log('onDeleteSuccess', data, variables, context);
            queryClient.invalidateQueries({queryKey}).then(r => {});
        },
        onError: (error, variables, context) => {
            setFeedbackSnackbar([buildErrorMessage(error), 'error']);
        }
    });

    // console.log('[DataGridAg]', data, columnDefs);

    const gridRef = useRef();
    const [editData, setEditData] = useState(null);
    const [dataToDelete, setDataToDelete] = useState(null);

    const tableColumns = [];
    const rowTemplate = {...defaultValue};

    const addRow = () => {setEditData({...rowTemplate});}
    const editRow = (node) => {setEditData(node.data);}
    const deleteRow = () => {deleteMutation.mutate(dataToDelete); setDataToDelete(null);}

    const onEditSave = (params) => {
        console.log('[onEditSave]', params);
        upsertMutation.mutate(params);
        setEditData(null);
    }

    if (! isSuccess) return null;

    const defaultColDef = {
        suppressMovable: true,
        sortable: false,
        wrapText: true,
        autoHeight: true,
    }

    columnDefs.forEach((col, idx) => {
        const column = {field: col.field};
        if (col.title) column.headerName = col.title;
        if (col.width) column.width = col.width;
        if (col.flex) column.flex = col.flex;
        if (col.cellRenderer) column.cellRenderer = col.cellRenderer;
        if (col.valueFormatter) column.valueFormatter = col.valueFormatter;
        if (col.valueGetter) column.valueGetter = col.valueGetter;
        if (col.headerTooltip) column.headerTooltip = col.headerTooltip;
        else if (col.type === 'select') {
            column.valueGetter = (params) => {
                if (col.options?.selectOptions) {
                    const selected = col.options.selectOptions.find(o => o.value === params.data[col.field]);
                    return selected ? selected.label : '<unknown>';
                }
                return params.data[col.field];
            }
        }
        if (col.cellStyle) column.cellStyle = col.cellStyle;
        column.cellClass = col.cellClass ? ['bf-form-hctable-cell'].concat(col.cellClass).join(" ") : 'bf-form-hctable-cell';
        column.headerClass = col.headerClass ? ['bf-form-hctable-header'].concat(col.headerClass) : ['bf-form-hctable-header'];
        switch (col.type) {
            case 'date':
                if (rowTemplate[col.field] === undefined)
                    rowTemplate[col.field] = dayjs().format('YYYY.MM.DD');
                break;
            case 'date_period_yyyymm':
                if (rowTemplate[col.field] === undefined)
                    rowTemplate[col.field] = `,,N`;
                if (! column.valueFormatter)
                    column.valueFormatter = (params) => {
                        const [from, to, current] = params.value.split(',');
                        return `${from} ~ ${current === 'Y' ? '(현재)' : to }`;
                    }
                break;
            case 'url':
                if (! column.cellRenderer) {
                    column.cellRenderer = (params) => {
                        return <a href={params.value} target="_blank" rel="noreferrer">{params.value}</a>
                    }
                }
                break;
            case 'text':
                column.wrapText = true;
                column.autoHeight = true;
                column.cellClass += ' text';
                break;
            default:
                if (rowTemplate[col.field] === undefined)
                    rowTemplate[col.field] = '';
        }
        tableColumns.push(column);
    });
    if (!readOnly) {
        tableColumns.push({
            field: '', width: 90, colId: 'ZZZZZZZZ', cellClass: 'bf-form-hctable-btncell',
            cellRenderer: (props) => {
                const editable = typeof notEditable === 'function' ?
                    ! notEditable(props.node.data) : ! notEditable;
                return editable && (<>
                    <IconButton size="small" onClick={() => editRow(props.node)}><EditIcon/></IconButton>
                    <IconButton size="small" onClick={() => setDataToDelete(props.node.data)}><DeleteIcon/></IconButton>
                </>);
            }
        });
    }

    const notes = getNote ? getNote(data) : null;
    return (
        <>
            {notes && notes.use && <Card sx={sx.noteBox} variant="outlined">
                <CardContent>
                    <Typography sx={sx.noteTitle}>{notes.title}</Typography>
                    <Box sx={sx.noteContents}>{notes.contents}</Box>
                </CardContent>
            </Card>}
            <Grid container className="data-grid-ag-container">
                {!readOnly && <Grid item xs={12} sx={{mb: 1}}>
                    <Button sx={{mt: 1}} variant="hc_contained_navy" onClick={addRow}>{addButtonTitle}</Button>
                </Grid>}
                {data.length > 0 &&
                <Grid item className="bf-form-hctable ag-theme-alpine" xs={12} sm={viewGridWidth}>
                    <AgGridReact columnDefs={tableColumns} rowData={data} defaultColDef={defaultColDef}
                                 ref={gridRef} domLayout="autoHeight"
                                 suppressRowHoverHighlight
                                 enableCellTextSelection
                                 tooltipShowDelay={500}
                                 getRowId={getRowId} animateRows={true}
                    />
                </Grid>}
                {(data.length === 0 && readOnly) &&
                <NoDataDiv message={"...데이터가 없습니다."}/>}
            </Grid>
            <HCTableEditDialog open={!! editData}
                               initValue={editData}
                               defaultValue={rowTemplate}
                               columnDefs={columnDefs}
                               onSave={onEditSave}
                               onCancel={() => setEditData(null)}
                               title={addDialogTitle}
            ></HCTableEditDialog>
            <ConfirmDialog open={!! dataToDelete} cancelExist
                           onOk={deleteRow}
                           onClose={() => setDataToDelete(null)}
                           message="정말로 삭제하시겠습니까?" />
        </>
    )
}

export default DataGridAg
