import {Box, Chip, IconButton, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import {useState} from "react";
import SaveBtnIcon from "@mui/icons-material/SaveAsOutlined";
import EditBtnIcon from "@mui/icons-material/BorderColorOutlined";
import VerifiedIndicator from "./VerifiedIndicator";
import CancelBtnIcon from "@mui/icons-material/DisabledByDefaultOutlined";

const sx = {
    container: {alignItems: "center",},
    titleBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "right"
    },
    valueBox: {
        display: "flex",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: 1,
        padding: "8px 14px",
        flex: 4,
        "&:hover": {border: "1px solid rgba(0, 0, 0, 0.87)",},
        minHeight: 24,
    },
    chipBox: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "-8px",
    },
    chip: {marginTop: "8px"},
    chipMultiline: {
        height: 'auto',
        '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal',
        },
    },
    btnContainer: {alignItems: "center",},
    selectBox: {flex: 1, minWidth: 120,}
};


function MultiSelectDataField({value, extrValue, title, fnSave, config}) {
    const {titleWidth, readOnly, hideEmpty, useMultiline} = config;
    const [editMode, setEditMode] = useState(false);
    const [data, setData] = useState(value || extrValue || []);
    const [newData, setNewData] = useState('');
    const [verified, setVerified] = useState(value !== null && value !== undefined);

    const handleStartEdit = () => {
        setEditMode(!editMode);
    }
    const handleSave = () => {
        fnSave(data);
        setEditMode(!editMode);
    }
    const handleCancelEdit = () => {
        setData(value || extrValue);
        setEditMode(!editMode);
    }
    const onChipDelete = (v) => {setData(data.filter(item => item !== v));};
    const onAddChip= (event) => {if (newData) { data.push(newData); setNewData(''); }};
    //console.log('[MultiSelectDataField]', title, value, extrValue, config, data, editMode, verified);

    const hide = hideEmpty && !data.length;

    return(
        !hide && (<Stack sx={sx.container} direction="row" spacing={1}>
            <Box sx={sx.titleBox} width={titleWidth}>
                <Typography sx={sx.title}>{title}</Typography>
                {verified && <VerifiedIndicator extrValue={extrValue} />}
            </Box>
            <Box sx={sx.valueBox}>
                <Stack sx={sx.chipBox} direction="row" spacing={1}>{data.map((item, index) => {
                    return <Chip style={sx.chip} size="small" label={item} sx={useMultiline ? sx.chipMultiline : null}
                                 onDelete={editMode ? () => onChipDelete(item) : null} key={index}></Chip>
                })}</Stack>
            </Box>
            {/*<Autocomplete sx={sx.selectBox} size="small" freeSolo
                          options={['haha', 'haha2', 'haha3']}
                          renderInput={(params) => <TextField {...params} />}/>*/}
            {editMode &&
            <TextField sx={sx.selectBox} size="small" value={newData} onChange={(e) => setNewData(e.target.value)}
                       InputProps={{endAdornment: (
                <InputAdornment position="end"><IconButton onClick={onAddChip} edge="end">
                    <ControlPointOutlinedIcon /></IconButton></InputAdornment>)}}
            />}
            {!readOnly && <Stack sx={sx.btnContainer} direction="row">
                {!editMode && <IconButton onClick={() => handleStartEdit()}><EditBtnIcon /></IconButton>}
                {editMode && <IconButton onClick={() => handleSave()}><SaveBtnIcon /></IconButton>}
                {editMode && <IconButton onClick={() => handleCancelEdit()}><CancelBtnIcon /></IconButton>}
            </Stack>}
        </Stack>)
    )
}

export default MultiSelectDataField;