import {useAtomValue} from "jotai/index";
import {metaAtom} from "../store/atom";
import DataGridAg from "./dataGridAg/DataGridAg";
import {apiWaggleGetCandidateBooks} from "../store/api_waggle";
import dayjs from "dayjs";
import {Tooltip} from "@mui/material";

function PersonReferencesBooks({ candidateId }) {
    const meta = useAtomValue(metaAtom);

    const columnDefs = [{
        field: 'published', title: '날짜', width: 100, type: 'string',
        valueGetter: p => ({
            'published': dayjs(p.data.published_at, 'YYYY년 MM월 DD일').format('YYYY.MM'),
            'publisher': p.data.publisher})
        ,
        cellRenderer: p => {
            return (
                <div>
                    <div>{p.value.published}</div>
                    <div style={{fontSize: '0.8125rem', color: '#666'}}>{p.value.publisher}</div>
                </div>
            )
        }
    }, {
        field: 'title', title: '제목', flex: 1, type: 'text',
        valueGetter: p => ({'title': p.data.title, 'subTitle': p.data.sub_title, 'url': p.data.book_url}),
        cellRenderer: p => (<div>
                <div><a href={p.value.url} target="_blank" rel="noreferrer">{p.value.title}</a></div>
                <div style={{fontSize: '0.8125rem', color: '#666'}}>{p.value.subTitle}</div>
            </div>)
    }, {
        field: 'writer', title: '저자', flex: 1, type: 'text',
        valueGetter: p => {
            const num_writers = p.data.writers_all?.length;
            const name = num_writers > 1 ? `${p.data.writer_info.name} 외 ${num_writers - 1}명` : p.data.writer_info.name;
            return {name, num_writers, writers: p.data.writers_all.join(', ')};
        },
        cellRenderer: p => p.value.num_writers > 1 ? <Tooltip title={p.value.writers}><span>{p.value.name}</span></Tooltip> :
            <span>{p.value.name}</span>
    }, {
        field: 'writer_keywords', title: '저자 키워드', flex: 1, type: 'text', cellClass: 'dark',
        valueGetter: p => (p.data.writer_analysis?.keyword||[]).join(', ')
    }, {
        field: 'writer_social_problem', title: '저자 사회문제', flex: 1, type: 'text', cellClass: 'dark',
        valueGetter: p => (p.data.writer_analysis?.social_problem||[]).join(', ')
    }, {/*
        field: 'writer_solution', title: '저자 솔루션', flex: 1, type: 'text', cellClass: 'dark',
        valueGetter: p => p.data.writer_analysis?.solution||[]
    }, {*/
        field: 'book_keywords', title: '책 키워드', flex: 1, type: 'text', cellClass: 'dark',
        valueFormatter: p => (p.value||[]).join(', ')
    /*}, {
        field: 'social_problem', title: '책 사회문제', flex: 1, type: 'text', cellClass: 'dark',*/
    }, {
        field: 'book_summary', title: '책 내용 요약', flex: 3, type: 'text', cellClass: 'dark',
    }];

    return (
        <DataGridAg
            queryKey={['waggle-candidate-books', candidateId]}
            queryFn={() => apiWaggleGetCandidateBooks(candidateId)}
            queryEnabled={!!meta}
            columnDefs={columnDefs}
            config={{readOnly: true, getRowId: (params) => params.data.uuid,
                getNote: (data) => { return {
                    title: `저자 소개: ${data[0]?.writer_info?.name}`,
                    contents: data[0]?.writer_info?.intro,
                    use: data && data.length > 0 && data[0]?.writer_info,
                }; }
            }} />
    )
}

export default PersonReferencesBooks;