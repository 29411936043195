import {
    apiWaggleDeleteCandidateFeedback,
    apiWaggleGetCandidateFeedbacks,
    apiWaggleGetCandidateInfo,
    apiWaggleGetNextReview,
    apiWaggleUpdateReviewAssign,
    apiWaggleUpsertCandidateFeedback
} from "../store/api_waggle";
import {feedbackSnackbarAtom, metaAtom, userInfoAtom} from "../store/atom";
import {useAtomValue, useSetAtom} from "jotai";
import DataGridAg from "./dataGridAg/DataGridAg";
import {boot2str, buildErrorMessage, utcDayjs} from "../util/misc";
import {Accordion, AccordionDetails, AccordionSummary, Button} from "@mui/material";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useState} from "react";

const sx = {
    btnCont: {position:'absolute', marginTop:8, paddingLeft:134, width:'100%', boxSizing:'border-box', display:'flex'},
    feedbackHeader: {fontWeight: 600},
}

function PersonFeedbackTable({candidateId}) {
    const meta = useAtomValue(metaAtom);
    const userInfo = useAtomValue(userInfoAtom);
    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    const feedbackQueryKey = ['waggle-candidate-feedbacks', candidateId];
    const infoQueryKey = ['waggle-candidate-info', candidateId];
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [numFeedbacks, setNumFeedbacks] = useState(undefined);

    const {data: candidateInfo} = useQuery({
        queryKey: infoQueryKey,
        queryFn: () => apiWaggleGetCandidateInfo(candidateId),
        enabled: !!meta,
    });
    const {data: nextReview} = useQuery({
        queryKey: ['waggle-candidate-next-review', candidateId],
        queryFn: () => apiWaggleGetNextReview(candidateId),
        enabled: !!meta,
    });

    const simpleFeedbackMutation = useMutation({
        mutationFn: apiWaggleUpsertCandidateFeedback,
        onSuccess: (resData, variables, context) => {
            console.log('simpleFeedbackMutation success', resData, variables, context);
            queryClient.invalidateQueries({queryKey: feedbackQueryKey}).then(r => {});
        },
        onError: (error, variables, context) => {
            console.log('simpleFeedbackMutation error', error, variables, context);
            setFeedbackSnackbar([buildErrorMessage(error), 'error']);
        }
    });
    const updateReviewAssignmentMutation = useMutation({
        mutationFn: apiWaggleUpdateReviewAssign,
        onSuccess: (resData, variables, context) => {
            console.log('apiWaggleUpdateReviewAssign success', resData, variables, context);
            queryClient.invalidateQueries({queryKey: infoQueryKey}).then(r => {});
        },
        onError: (error, variables, context) => {
            console.log('apiWaggleUpdateReviewAssign error', error, variables, context);
            setFeedbackSnackbar([buildErrorMessage(error), 'error']);
        }
    });

    if (! candidateInfo) return null;
    //console.log('[PersonFeedbackTable', candidateId, candidateInfo);

    const columnDefs = [{
        field: 'writer', title: '작성자', width: 115, type: 'string',
        valueFormatter: (params) => boot2str(params.value),
        options: {mandatory: true},
    }, {
        field: 'related_business', title: '관련 재단 사업', width: 140, type: 'select',
        options: {
            selectOptions: meta?.domain.waggle.bi_business.map(d => ({label:d.title, value:d.id})),
            mandatory: true,
        },
    }, {
        field: 'pool', title: '가능 관계', width: 140, type: 'select',
        options: {
            selectOptions: meta?.domain.waggle.bi_candidate_roles.map(d => ({label:d.title, value:d.id})),
            mandatory: true,
        },
    }, {
        field: 'rank', title: '관심도', width: 70, type: 'select',
        options: {
            selectOptions: [1, 2, 3, 4, 5].map(n => ({label:n, value:n})),
            mandatory: true,
        },
    }, {
        field: 'comment', title: '코멘트', flex: 1, type: 'text',
        options: {mandatory: false},
    }, {
        field: 'updated_at', title: '수정일', width: 136, type: 'defined',
        valueFormatter: (params) => utcDayjs(params.value).format('YY-MM-DD HH:mm'),
        options: {hidden: (v) => !v},
    }];
    const defaultValue = {
        writer: userInfo.email,
        candidate_id: candidateId,
    }
    const simpleFeedbackForFellow = {
        ...defaultValue,
        related_business: "brianfellow",
        pool: "fellow_candidate",
        rank: 3,
        comment: ''
    }

    const assign_info = (candidateInfo.personal_info?.review?.assignments||[]).filter(a => a.assignee === userInfo.email)?.[0];

    const doSimpleFeedback = () => {
        simpleFeedbackMutation.mutate(simpleFeedbackForFellow);
    }

    const updateReviewAssignment = () => {
        const operation = assign_info.completed_at ? 'undo' : 'reviewed';
        updateReviewAssignmentMutation.mutate({candidate_id: candidateId, assignee: userInfo.email, operation});
    }

    //console.log('PersonFeedbackTable', candidateId, candidateInfo, nextReview?.next);

    return (<div>
    <Accordion defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <p style={sx.feedbackHeader}>재단 피드백 및 리뷰 {numFeedbacks !== undefined && `(${numFeedbacks})`}</p>
        </AccordionSummary>
        <AccordionDetails>
        <div style={{position:'relative'}}>
        <div style={sx.btnCont}>
            <Button variant="hc_contained_navy" onClick={doSimpleFeedback}>간편 피드백 (펠로우 후보)</Button>
            {assign_info &&
            <Button sx={{ml:1, backgroundColor:assign_info.completed_at ? '#99784c':null}}
                    onClick={updateReviewAssignment} variant="hc_contained_orange"
            >{assign_info.completed_at ? '리뷰 완료됨' : '리뷰 완료'}</Button>}
            {/*<Box sx={{flexGrow:1}}></Box>*//*onClick={() => navigate(`/waggle/candidates/${nextReview?.next}`)}*/}
            <Button sx={{ml:1}} variant="hc_contained_navy" disabled={!nextReview?.next}
                    href={`/waggle/candidates/${nextReview?.next}`}>다음 리뷰 후보로</Button>
            <Button sx={{ml:1}} variant="hc_contained_navy"
                    onClick={() => navigate(`/waggle/candidates?assigned=Y`)}>목록으로</Button>
        </div>
        <DataGridAg
            queryKey={feedbackQueryKey}
            queryFn={() => apiWaggleGetCandidateFeedbacks(candidateId)}
            queryEnabled={!!meta}
            upsertMutateFn={apiWaggleUpsertCandidateFeedback}
            deleteMutateFn={apiWaggleDeleteCandidateFeedback}
            columnDefs={columnDefs}
            defaultValue={defaultValue}
            config={{
                addButtonTitle: '피드백 추가하기',
                getRowId: (params) => params.data.feedback_id,
                notEditable: (d) => d.writer !== userInfo.email,
                onFetched: (fetchedData) => setNumFeedbacks((fetchedData||[]).length)
            }} />
        </div>
        </AccordionDetails>
    </Accordion>
    </div>)
}

export default PersonFeedbackTable;
