import PersonInfo from "../components/PersonInfo";
import {Stack} from "@mui/material";
import PersonFeedbackTable from "../components/PersonFeedbackTable";
import {useParams} from "react-router-dom";
import PersonReferences from "../components/PersonReferences";
import PersonHoneycombHistory from "../components/PersonHoneycombHistory";

const sx = {
    container: {
        marginTop: "100px",
    }
}

function WagglePersonView() {
    const {candidateId} = useParams();

    return (
        <Stack sx={sx.container} spacing={3}>
            <PersonInfo candidateId={candidateId} />
            <PersonHoneycombHistory candidateId={candidateId} />
            <PersonFeedbackTable candidateId={candidateId} />
            <PersonReferences candidateId={candidateId} />
        </Stack>
    );
}

export default WagglePersonView;