import {Box, FormControl, IconButton, OutlinedInput, Stack, Typography} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import EditBtnIcon from '@mui/icons-material/BorderColorOutlined';
import CancelBtnIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import SaveBtnIcon from '@mui/icons-material/SaveAsOutlined';
import VerifiedIndicator from "./VerifiedIndicator";
import {checkEmptyValue} from "../../util/misc";


const sx = {
    container: {alignItems: "center",},
    btnContainer: {alignItems: "center",},
    titleBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "right"
    }
};



function TextDataField({
    title, value, extrValue, config, fnSave
}) {
    const {titleWidth, readOnly, hideEmpty} = config;
    const [data, setData] = useState('');
    const [editMode, setEditMode] = useState(false);
    const verified = value !== null && value !== undefined;
    const inputRef = useRef();

    useEffect(() => {
        setData(value || extrValue || '')
    }, [value, extrValue]);

    const handleStartEdit = () => {
        setEditMode(!editMode);
        inputRef.current.focus();
    }
    const handleSave = () => {
        fnSave(data);
        setEditMode(!editMode);
    }
    const handleCancelEdit = () => {
        setData(value || extrValue);
        setEditMode(!editMode);
    }

    const hide = hideEmpty && checkEmptyValue(data);

    //console.log('[TextDataField]', title, value, extrValue, config, data, editMode, verified);

    return (
        !hide && (<Stack sx={sx.container} direction="row" spacing={1}>
            <Box sx={sx.titleBox} width={titleWidth}>
                <Typography sx={sx.title} >{title}</Typography>
                {verified && <VerifiedIndicator extrValue={extrValue} />}
            </Box>
            <FormControl variant="outlined" style={{flexGrow:1}}>
                <OutlinedInput
                    readOnly={! editMode} inputRef={inputRef} size="small"
                    value={data}
                    onChange={(ev)=>{setData(ev.target.value);}} />
            </FormControl>
            {!readOnly && <Stack sx={sx.btnContainer} direction="row">
                {!editMode && <IconButton onClick={() => handleStartEdit()}><EditBtnIcon /></IconButton>}
                {editMode && <IconButton onClick={() => handleSave()}><SaveBtnIcon /></IconButton>}
                {editMode && <IconButton onClick={() => handleCancelEdit()}><CancelBtnIcon /></IconButton>}
            </Stack>}
        </Stack>)
    )
}

export default TextDataField;