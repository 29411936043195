import {apiWaggleGetCandidateFacebookPosts} from "../store/api_waggle";
import DataGridAg from "./dataGridAg/DataGridAg";
import {metaAtom} from "../store/atom";
import {useAtomValue} from "jotai";

function PersonReferencesFacebook({ candidateId }) {

    const meta = useAtomValue(metaAtom);

    const columnDefs = [{
        field: 'post_created_at', title: '날짜', width: 80, type: 'string',
    }, {
        field: 'writer', title: '작성자', width: 80, type: 'string',
        valueGetter: p => ({'writer': p.data.writer, 'post_url': p.data.post_url}),
        cellRenderer: p => (<a href={p.value.post_url} target="_blank" rel="noreferrer">{p.value.writer}</a>)
    }, {
        field: 'post_keywords', title: '키워드', flex: 1, type: 'text', cellClass: 'dark',
        valueFormatter: p => (p.value||[]).join(', ')
    }, {
        field: 'social_problem', title: '사회문제', flex: 1, type: 'text', cellClass: 'dark',
        valueFormatter: p => (p.value||[]).join(', ')
    }, {
        field: 'solution', title: '솔루션', flex: 1, type: 'text', cellClass: 'dark',
        valueFormatter: p => (p.value||[]).join(', ')
    }, {
        field: 'post_summary', title: '내용요약', flex: 2, type: 'text', cellClass: 'dark',
    }, {
        field: 'content', title: '내용', flex: 2, type: 'text',
    }];

    return (
        <DataGridAg
            queryKey={['waggle-candidate-facebook', candidateId]}
            queryFn={() => apiWaggleGetCandidateFacebookPosts(candidateId)}
            queryEnabled={!!meta}
            columnDefs={columnDefs}
            config={{readOnly: true, getRowId: (params) => params.data.uuid}} />
    )
}

export default PersonReferencesFacebook;