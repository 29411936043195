import {clearTokenCookie, getTokenCookie} from "../store/cookies";
import {metaAtom, tokenAtom, userInfoAtom} from "../store/atom";
import {useAtom} from "jotai";
import {useEffect, useState} from "react";
import {apiConnect} from "../store/api_user";
import {useQuery} from "@tanstack/react-query";
import {AppBar, Button, Menu, MenuItem, Stack} from "@mui/material";
import {useMatch, useNavigate} from "react-router-dom";
import classNames from "classnames";

function Header(props) {

    const [token, setToken] = useAtom(tokenAtom);
    const [userInfo, setUserInfo] = useAtom(userInfoAtom);
    const [meta, setMeta] = useAtom(metaAtom);

    const [menuAnchor, setMenuAnchor] = useState(null);
    const navigate = useNavigate();

    const {data, isSuccess, isError, error}= useQuery(
        ['connect'],
        () => apiConnect(),
        { enabled: !!token }
    );

    const logout = (useRedirect) => {
        clearTokenCookie();
        setToken(null);
        setUserInfo(null);
        setMeta(null);
        if (useRedirect) {
            const redirect = encodeURIComponent(window.location.pathname + window.location.search)
            window.location.href = `/login?redirect=${redirect}`;
        } else {
            window.location.href = '/login';
        }
    }
    const closeMenu= () => { setMenuAnchor(null); }

    useEffect(() => {
        const token = getTokenCookie();
        if (! token) logout(true);
        else {
            setToken(token);
            if (data) {
                setUserInfo(data["user"]);
                setMeta(data["meta"]);
                console.log('[Header] connected', data);
            }
        }
    }, [data]);

    if (isError) {
        if (error?.cause?.status === 401 || error?.cause?.status === 404) {
            console.error('[Header] connect error', error.message, error.cause);
            logout(true);
        } else {
            console.log('[Header] error', error);
        }
    }

    const MENU_BUTTONS = [
        {to: `/waggle/candidates`, title: "waggle", use: true}
    ]

    const MenuButtonComp = ({use, to, title}) => {
        const selected = useMatch(to)
        return (use && <Button className={classNames("btn-ul", {selected: selected})}
                               onClick={()=>navigate(to)}>{title}</Button>);
    }

    const profile = process.env.REACT_APP_STAGE || 'dev';
    const apiProfile = meta?.['api_profile'];
    const name = userInfo?.name || '';

    return (<>
        <AppBar className='bf-header' position='fixed' color='white'>
            <Stack direction='row' alignItems="center">
                <a href="/" className='brianimpact-logo-small' />
                {(profile === 'dev' || apiProfile === 'dev') &&
                    <div className='profile-info'><span>front-{profile}</span><br /><span>api-{apiProfile}</span></div>}
            </Stack>
            <Stack direction='row'>
                {MENU_BUTTONS.map((button, idx) => <MenuButtonComp key={idx} {...button} />)}
                <Button id="hc-header-user-btn" className="btn-user" variant="hc_outlined_black"
                        onClick={(e) => setMenuAnchor(e.currentTarget)}>{name}</Button>
                <Menu id="menu-appbar"
                      anchorEl={menuAnchor}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                      open={Boolean(menuAnchor)}
                      onClose={closeMenu}>
                    <MenuItem key='logout' onClick={() => logout()}>로그아웃</MenuItem>
                </Menu>
            </Stack>
        </AppBar>
    </>);
}

export default Header;