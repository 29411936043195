import { Cookies } from 'react-cookie'

const cookie = new Cookies();

function setTokenCookie(token, token_type='token') {
    cookie.set(token_type, token, { path: '/', maxAge: 60*60*24*7, sameSite: 'strict', secure: true });
}

function getTokenCookie(token_type = 'token') {
    return cookie.get(token_type);
}

function clearTokenCookie() {
    cookie.remove('token', { path: '/' });
    cookie.remove('refresh_token', { path: '/' });
}

export {
    setTokenCookie,
    getTokenCookie,
    clearTokenCookie,
}
