import {
    Autocomplete,
    Box,
    Chip,
    FormControl,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Stack,
    TextField
} from "@mui/material";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import {useState} from "react";

const sx = {
    container: {alignItems: "top",},
    valueBox: {
        display: "flex",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: 1,
        padding: "8px 14px",
        flex: 4,
        "&:hover": {border: "1px solid rgba(0, 0, 0, 0.87)",},
    },
    chipBox: {display: "flex", flexWrap: "wrap", marginTop: "-8px",},
    chip: {marginTop: "8px"},
    addBox: {alignItems: "center",},
    addSelect: {flex: 1, minWidth: 100},
    addBtn: {paddingLeft:"2px"},
};

function MultiSelect({value, onChange, options, config}) {

    const [newData, setNewData] = useState('');
    const {selectMinWidth} = config || {};
    // console.log('[MultiSelect]', value, newData);
    const onChipDelete = (v) => {onChange(value.filter(item => item !== v));};
    const onAddChip= () => {if (newData) { onChange([...value, newData]); setNewData(''); }};

    return (
    <Stack sx={sx.container} direction="row" spacing={1}>
        <Box sx={sx.valueBox}>
            <Stack sx={sx.chipBox} direction="row" spacing={1}>
                {(value||[]).map((v, idx) =>
                    <Chip style={sx.chip} key={idx} size="small" label={v} onDelete={() => onChipDelete(v)}></Chip>)}
            </Stack>
        </Box>
        <Stack sx={sx.addBox} direction="row">
            <Autocomplete sx={sx.addSelect} size="small" placeholder="추가하기" freeSolo
                          {...(selectMinWidth ? {style: {minWidth: selectMinWidth}} : {})}
                      value={newData}
                      onChange={(e, item) => setNewData(item ? item.value : '')}
                      options={options} label="추가"
                      renderInput={(params) => <TextField {...params} />}/>
            <IconButton sx={sx.addBtn} onClick={onAddChip}><ControlPointOutlinedIcon /></IconButton>
        </Stack>
    </Stack>);
}

export default MultiSelect;