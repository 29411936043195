import {Box, Tab, Tabs} from "@mui/material";
import PersonReferencesNews from "./PersonReferencesNews";
import PersonReferencesBooks from "./PersonReferencesBooks";
import PersonReferencesYouTube from "./PersonReferencesYouTube";
import PersonReferenceBeesEamReport from "./PersonReferenceBeesEamReport";
import {useEffect, useState} from "react";
import PersonReferencesFacebook from "./PersonReferencesFacebook";
import {useQuery} from "@tanstack/react-query";
import {apiWaggleGetCandidateReferenceResourceCounts} from "../store/api_waggle";
import {useAtomValue} from "jotai/index";
import {metaAtom} from "../store/atom";

const sx = {
    container: {
        minHeight: 300,
    },
}

function PersonReferences({ candidateId }) {
    const meta = useAtomValue(metaAtom);
    const {isSuccess, data} = useQuery({
        queryKey: ['waggle-candidate-reference-counts', candidateId],
        queryFn: () => apiWaggleGetCandidateReferenceResourceCounts(candidateId),
        enabled: !!meta,
    });
    const [value, setValue] = useState('bees-eam-report');

    /*
    useEffect(() => {
        if (data) {
            if (data.num_news) setValue('news');
            else if (data.num_books) setValue('books');
            else if (data.num_facebook_posts) setValue('facebook');
            else if (data.num_youtube_contents) setValue('youtube');
        }
    }, [data]);
     */

    if (!data) return null;
    const buildLabel = (label, count) => count > 0 ? `${label} (${count})` : label;

    return (<>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={value} onChange={(ev, newValue) => setValue(newValue)}>
                <Tab label="BEES EAM 리포트" value="bees-eam-report"/>
                <Tab label={buildLabel("뉴스기사", data?.num_news)} value="news"/>
                <Tab label={buildLabel("책", data?.num_books)} value="books"/>
                <Tab label={buildLabel("페이스북", data?.num_facebook_posts)} value="facebook"/>
                <Tab label={buildLabel("유튜브", data?.num_youtube_contents)} value="youtube"/>
            </Tabs>
        </Box>
        <Box sx={sx.container}>
            {value === 'news' && <PersonReferencesNews candidateId={candidateId} />}
            {value === 'books' && <PersonReferencesBooks candidateId={candidateId} />}
            {value === 'facebook' && <PersonReferencesFacebook candidateId={candidateId} />}
            {value === 'youtube' && <PersonReferencesYouTube candidateId={candidateId} />}
            {value === 'bees-eam-report' && <PersonReferenceBeesEamReport candidateId={candidateId} />}
        </Box>
    </>);
}

export default PersonReferences;