import {useGoogleLogin} from "@react-oauth/google";
import {Button, Stack, SvgIcon} from "@mui/material";
import '../css/login.css';
import {apiLogin} from "../store/api_user";
import {feedbackSnackbarAtom} from "../store/atom";
import {useSetAtom} from "jotai";
import {useSearchParams} from "react-router-dom";

function GoogleIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
            <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
            <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
            <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
            <path d="M1 1h22v22H1z" fill="none"/>
        </SvgIcon>
    );
}

const Login = () => {
    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    const [searchParams, setSearchParams] = useSearchParams();
    const googleLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async (codeResponse) => {
            apiLogin(codeResponse.code).then(() => {
                const redirect = searchParams.get('redirect');
                window.location.href = redirect || '/';
            }).catch((e) => {
                console.log('apiLogin Error', e);
                setFeedbackSnackbar([`구글 로그인 중 문제가 발생했습니다: ${e.message}`, 'error']);
            });
        },
        onError: errorResponse => {
            console.log("Google Login Error", errorResponse);
            setFeedbackSnackbar(['구글 로그인 중 오류가 발생했습니다', 'error']);
        },
    });

    return (
        <div className="bat-center-container">
                <Stack className="bat-login">
                    <div className="header"><div className="logo"></div></div>
                    <div className="contents">
                        <Button variant={"hc_contained_navy"} startIcon={<GoogleIcon/>} size="large"
                                onClick={() => googleLogin()}>구글로 로그인</Button>
                    </div>
                    <div className="login-bottom">
                        <p>brianimpact.org 구글계정으로만 로그인 가능합니다</p>
                    </div>
                </Stack>
            </div>
    );
};

export default Login