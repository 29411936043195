import {useAtomValue, useSetAtom} from "jotai/index";
import {feedbackSnackbarAtom, metaAtom, userInfoAtom} from "../store/atom";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {apiWaggleGetCandidateBeesEamReport} from "../store/api_waggle";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useEffect, useState} from "react";

const sx = {
    header: {fontWeight: 600, fontSize: "1.25rem", marginTop: 2, marginBottom: 2,},
    question: {fontWeight: 600,},
    answer: {whiteSpace: "pre-wrap"},
    cotContainer: {margin: "32px 32px 0 32px", padding: "16px 16px", fontSize: "0.875rem"},
    cotLabel: {fontWeight:600, color:"#fff", backgroundColor:"#000", padding:"2px 8px", marginBottom:"auto", borderRadius:5},
    scoreLabel: (wins) => {return {fontWeight:600, color:"#fff", backgroundColor: wins ? "#FF991F" : "#000",
        padding:"0 8px", marginRight:8, borderRadius:5, marginBottom:"auto"}},
    cotSourceType: {color: "#fff", backgroundColor: "#5c6ba5", fontWeight: 600, padding: "0px 4px", marginRight: 4, fontSize: "0.75rem", borderRadius: 4},
    cotSource: {fontSize: "0.75rem", marginLeft: 6},
    cotListItem: (idx) => {return idx > 0 ? {marginTop: 6} : {}},
    totalScore: {fontSize: "1rem", fontWeight: 600, marginLeft: 6, color: "##69645e"},
}

function EamReportItemElement({ item }) {
    function CotItemElement({ cotItem }) {
        return <>
            <Chip label={cotItem.source} size="small" />
            {cotItem.quote}
            {cotItem.url && <a href={cotItem.url} target="_blank" rel="noreferrer">{cotItem.title || "출처"}</a>}
        </>
    }
    function CotElement({ cot }) {
        if (!cot || cot.length === 0) return null;
        return (<Paper variant="outlined" sx={sx.cotContainer}>
            <Stack direction="row">
                <span style={sx.cotLabel}>COT</span>
                <ul>{cot.map((cotItem, idx) => <li key={idx} style={sx.cotListItem(idx)}><>
                    {cotItem.source_type && <span style={sx.cotSourceType}>{cotItem.source_type}</span>}
                    <span>{cotItem.quote}</span>
                    <span style={sx.cotSource}>{cotItem.url && <a href={cotItem.url} target="_blank" rel="noreferrer">[{cotItem.title || "출처"}]</a>}</span>
                </></li>)}</ul>
            </Stack>
        </Paper>)
    }
    function ScoreElement({ item }) {
        if (item.s_score_point === 0) return null;
        const score = item.wins_s_score_point ? `+${item.s_score_point}` : '0';
        return <span style={sx.scoreLabel(item.wins_s_score_point)}>{score}</span>;
    }

    const answerText = typeof item.answer === "boolean" ?
        (item.answer ? "예" : "아니오") :
        item.answer || "🐝🐝 ...잘 모르겠습니다. 🐝🐝";

    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ScoreElement item={item} />
                <Typography sx={sx.question}>{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography sx={sx.answer}>{answerText}</Typography>
                <CotElement cot={item.cot} />
            </AccordionDetails>
        </Accordion>
    )

}

function PersonReferenceBeesEamReport({ candidateId }) {
    const meta = useAtomValue(metaAtom);
    const userInfo = useAtomValue(userInfoAtom);
    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    const [selectedReport, setSelectedReport] = useState(null);

    const queryClient = useQueryClient();
    const queryKey = ['waggle-person-bees-ead', candidateId];
    const {isSuccess, data} = useQuery({
        queryKey: queryKey,
        queryFn: () => apiWaggleGetCandidateBeesEamReport(candidateId),
        enabled: !!meta,
    });
    //console.log('PersonReferenceBeesEamReport', candidateId, data);
    useEffect(() => {
        if (data && data.length > 0) {
            setSelectedReport(data[0].source);
            data.forEach(item => {
                item.report.questions.forEach(qa => {
                    if (typeof qa.answer === "string") qa.answer = qa.answer.replaceAll("\\n", "\n");
                });
            });
        }
    }, [data]);

    if (!isSuccess) return null;
    if (!data || data.length === 0) {
        return <div>...데이터가 없습니다</div>;
    } else if (!selectedReport) return null;

    const currentReport = (data.find(item => item.source === selectedReport)||{}).report;
    let totalScore = 0
    currentReport.questions.forEach(qa => {totalScore += qa.s_score_point});

    return (<div>
        <FormControl fullWidth>
            <InputLabel id="eam-report-label">종류</InputLabel>
            <Select labelId="demo-label-select-label" id="eam-report-select"
                    value={selectedReport} label="종류"
                    onChange={() => {}}
            >{data.map((item, idx) => <MenuItem key={idx} value={item.source}>{item.source}</MenuItem>)}</Select>
        </FormControl>
        <Box>
            <Typography sx={sx.header}>
                EAM Score: <span>{currentReport.secondary_score}</span><span style={sx.totalScore}>({totalScore})</span>
            </Typography>
            <div>
                {currentReport.questions.map((item, idx) => <EamReportItemElement key={idx} item={item} />)}
            </div>
        </Box>
    </div>);
}

export default PersonReferenceBeesEamReport;