import {atom} from 'jotai'

const tokenAtom = atom(null);
const userInfoAtom = atom(null);
const metaAtom = atom(null);

const feedbackSnackbarAtom = atom(['', 'info']);

export {
    tokenAtom,
    userInfoAtom,
    metaAtom,
    feedbackSnackbarAtom,
}