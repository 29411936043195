import {useLocation} from "react-router-dom";
import {useMemo} from "react";

function useQueryParams() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

function objectToQueryString(obj, omitEmpty = false) {
    const params = new URLSearchParams();
    for (const key in obj) {
        if (omitEmpty && !obj[key]) continue;
        params.append(key, obj[key]);
    }
    return params.toString();
}

export {
    useQueryParams,
    objectToQueryString,
}