import Header from "./Header";
import Footer from "./Footer";
import {Outlet} from "react-router-dom";

function Layout(props) {
    return (
        <div className="bf-root">
            <Header {...props} />
            <Outlet />
            <Footer {...props} />
        </div>
    )
}

export default Layout;