import TextDataField from "./dataFields/TextDataField";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import MultiSelectDataField from "./dataFields/MultiSelectDataField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Fragment, useEffect, useState} from "react";
import {useAtomValue, useSetAtom} from "jotai";
import {feedbackSnackbarAtom, metaAtom, userInfoAtom} from "../store/atom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {
    apiWaggleGetCandidateInfo,
    apiWaggleReportDataError,
    apiWaggleSetCandidateTrivial,
    apiWaggleUpdateCandidateInfoField
} from "../store/api_waggle";
import {boot2str, buildErrorMessage, utcDayjs} from "../util/misc";
import {Helmet} from "react-helmet-async";

const sx = {
    hrStyle: {marginTop:30, marginBottom:30, border:"0.5px solid #e0e0e0"},
    header: {width:"100%"},
    headerContent: {fontSize: "1.125em", fontWeight: 500},
    headerControlBox: {marginRight:10},
};

function PersonInfo({ candidateId }) {

    const meta = useAtomValue(metaAtom);
    const userInfo = useAtomValue(userInfoAtom);
    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);

    const queryClient = useQueryClient();
    const queryKey = ['waggle-candidate-info', candidateId];
    const {isSuccess, data} = useQuery({
        queryKey: queryKey,
        queryFn: () => apiWaggleGetCandidateInfo(candidateId),
        enabled: !!meta,
    });
    const [personInfo, setPersonInfo] = useState(null);
    const [additionalInfo, setAdditionalInfo] = useState(null);
    const [daumProfileInfo, setDaumProfileInfo] = useState([]);
    const [hideEmpty, setHideEmpty] = useState(true);
    const [reportDlgOpen, setReportDlgOpen] = useState(false);
    const [reportContents, setReportContents] = useState('');

    useEffect(() => {
        if (!data) return;
        if (typeof data.personal_info?.job === 'string') data.personal_info.job = [data.personal_info.job];
        if (typeof data.personal_info?.associations === 'string') data.personal_info.associations = [data.personal_info.associations];
        setPersonInfo(data.personal_info);
        setAdditionalInfo(data.additional_info);
        setDaumProfileInfo(data.daum_profile_info || [])
    }, [data]);

    const saveFieldMutation = useMutation({
        mutationFn: apiWaggleUpdateCandidateInfoField,
        onSuccess: (resData, variables, context) => {
            console.log('saveField Success', data, variables, context);
            setFeedbackSnackbar(['저장되었습니다.', 'info']);
            queryClient.invalidateQueries(queryKey).then(r => {});
        },
        onError: (error, variables, context) => {
            setFeedbackSnackbar([buildErrorMessage(error), 'error']);
        }
    });

    const setTrivialMutation = useMutation({
        mutationFn: apiWaggleSetCandidateTrivial,
        onSuccess: (resData, variables, context) => {
            console.log('setTrivialMutation Success', data, variables, context);
            queryClient.invalidateQueries(queryKey).then(r => {});
        },
        onError: (error, variables, context) => {
            console.log('setTrivialMutation Error', error, variables, context);
            setFeedbackSnackbar([buildErrorMessage(error), 'error']);
        }
    });

    const reportDataErrorMutation = useMutation({
        mutationFn: apiWaggleReportDataError,
        onSuccess: (resData, variables, context) => {
            setFeedbackSnackbar(['신고가 접수되었습니다.', 'success']);
        },
        onError: (error, variables, context) => {
            setFeedbackSnackbar([buildErrorMessage(error), 'error']);
        }
    });

    //console.log('[PersonInfo]', candidateId, data);

    if (! personInfo) {
        return null;
    }

    const buildTitle = (info) => {
        const nick = info.pseudonym || info.extr_pseudonym
        return nick ? `${info.name} (${nick})` : info.name;
    }

    const fnSaveField = (field, value) => {
        saveFieldMutation.mutate({
            candidateId: candidateId,
            field: field,
            value: value,
        })
    }

    const fnSaveAdditionalField = (field, value) => {

    }

    const onChangeCandidateTrivial = (e) => {
        setTrivialMutation.mutate({ids: [candidateId], is_trivial: !personInfo.trivial});
        e.stopPropagation();
    }

    const onChangeHideEmpty = (e) => {
        setHideEmpty(!hideEmpty);
        e.stopPropagation();
    }

    const onOpenReportDlg = (e) => {
        setReportContents('');
        setReportDlgOpen(true);
        e.stopPropagation();
    }

    const onReportDataError = (e) => {
        reportDataErrorMutation.mutate({error_type: 'etc', candidate_ids: [candidateId], comment: reportContents});
        setReportDlgOpen(false);
    }

    const titleWidth = 110;
    const createdTimeStr = utcDayjs(personInfo.created_at).format('YYYY-MM-DD HH:mm:ss');
    const updatedTimeStr = `${utcDayjs(personInfo.updated_at).format('YYYY-MM-DD HH:mm:ss')} (${boot2str(personInfo.updated_by)})`;
    const infoSection = (
        <Stack spacing={1}>
            <TextDataField title="이름" value={null} extrValue={personInfo.name}
                           config={{readOnly: true, titleWidth: titleWidth}} />
            <TextDataField title="활동명" value={personInfo.pseudonym} extrValue={personInfo.extr_pseudonym}
                           fnSave={(value) => fnSaveField('pseudonym', value)}
                           config={{readOnly: true, titleWidth: titleWidth, hideEmpty}} />
            <TextDataField title="국적" value={personInfo.nationality} extrValue={personInfo.extr_nationality}
                           fnSave={(value) => fnSaveField('nationality', value)}
                           config={{readOnly: true, titleWidth: titleWidth, hideEmpty}} />
            <Grid container>
                <Grid item xs={4}>
                    <TextDataField title="나이대" value={personInfo.age_band} extrValue={personInfo.extr_age_band}
                           fnSave={(value) => fnSaveField('age_band', value)}
                           config={{readOnly: true, titleWidth: titleWidth, hideEmpty}} />
                </Grid>
                <Grid item xs={4}>
                    <TextDataField title="나이" value={personInfo.age} extrValue={personInfo.extr_age}
                           fnSave={(value) => fnSaveField('age', value)}
                           config={{readOnly: true, titleWidth: titleWidth, hideEmpty}} />
                </Grid>
                <Grid item xs={4}>
                    <TextDataField title="생년월일" value={personInfo.birth_date} extrValue={personInfo.extr_birth_date}
                           fnSave={(value) => fnSaveField('birth_date', value)}
                           config={{readOnly: true, titleWidth: titleWidth, hideEmpty}} />
                </Grid>
            </Grid>
            <MultiSelectDataField title="직업" value={personInfo.job} extrValue={personInfo.extr_job}
                                  fnSave={(value) => fnSaveField('job', value)}
                                  config={{readOnly: true, titleWidth: titleWidth, hideEmpty}}/>
            <MultiSelectDataField title="소속" value={personInfo.associations} extrValue={personInfo.extr_associations}
                                  fnSave={(value) => fnSaveField('associations', value)}
                                  config={{readOnly: true, titleWidth: titleWidth, hideEmpty}}/>
            <MultiSelectDataField title="관련어" value={personInfo.associated_words} extrValue={personInfo.extr_associated_words}
                                  fnSave={(value) => fnSaveField('associated_words', value)}
                                  config={{readOnly: true, titleWidth: titleWidth, hideEmpty}}/>
            <MultiSelectDataField title="키워드" value={personInfo.keywords} extrValue={personInfo.extr_keywords}
                                  fnSave={(value) => fnSaveField('keywords', value)}
                                  config={{readOnly: true, titleWidth: titleWidth, hideEmpty}}/>
            <TextDataField title="생성일" titleWidth={titleWidth}  value={null} extrValue={createdTimeStr}
                           config={{readOnly: true, titleWidth: titleWidth, hideEmpty}} />
            <TextDataField title="수정일" titleWidth={titleWidth}  value={null} extrValue={updatedTimeStr}
                           config={{readOnly: true, titleWidth: titleWidth, hideEmpty}} />
        </Stack>
    );

    const additionalSection = (
        <Stack spacing={1}>
            <MultiSelectDataField title="직업이력" value={additionalInfo.work_history} extrValue={additionalInfo.extra_work_history}
                                  fnSave={(value) => fnSaveAdditionalField('work_history', value)}
                                  config={{readOnly: true, titleWidth: titleWidth, hideEmpty, useMultiline: true}}/>
            <MultiSelectDataField title="교육이력" value={additionalInfo.education_history} extrValue={additionalInfo.extra_education_history}
                                  fnSave={(value) => fnSaveAdditionalField('education_history', value)}
                                  config={{readOnly: true, titleWidth: titleWidth, hideEmpty, useMultiline: true}}/>
            <MultiSelectDataField title="수상이력" value={additionalInfo.award_history} extrValue={additionalInfo.extra_award_history}
                                  fnSave={(value) => fnSaveAdditionalField('award_history', value)}
                                  config={{readOnly: true, titleWidth: titleWidth, hideEmpty, useMultiline: true}}/>
            <MultiSelectDataField title="기타추출정보" value={additionalInfo.extra_info} extrValue={additionalInfo.extra_info}
                                  config={{readOnly: true, titleWidth: titleWidth, hideEmpty, useMultiline: true}}/>
        </Stack>
    )
    const additionalInfoExist = additionalInfo && additionalInfo.personal_info_id;
    const daumProfile = daumProfileInfo && daumProfileInfo.length > 0 ? daumProfileInfo[0] : null;

    return (<>
        <Helmet>
            <title>{buildTitle(personInfo)} | BAT</title>
        </Helmet>
        <Accordion elevation={3} defaultExpanded={true} style={personInfo?.trivial ? {backgroundColor:"#e3cbcf"} : {}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack style={sx.header} direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <Typography sx={sx.headerContent}>{buildTitle(personInfo)}</Typography>
                        {daumProfile &&
                            <Button variant="outlined" color="black" size="small" component="a"
                                    href={daumProfile.dp_url} target="_blank" rel="noreferrer"
                                    onClick={(e) => {e.stopPropagation();}}>daum</Button>}
                    </Stack>
                    <Stack style={sx.headerControlBox} direction="row" alignItems="center" spacing={1}>
                        <Button onClick={onChangeCandidateTrivial}>{personInfo?.trivial ? "후보자 제외 취소": "후보자 제외하기"}</Button>
                        <Button onClick={onChangeHideEmpty}>{hideEmpty ? "모든 값 보기": "빈 값 숨기기"}</Button>
                        <Button onClick={onOpenReportDlg}>데이터 신고하기</Button>
                    </Stack>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                {infoSection}
                {additionalInfoExist && (<hr style={sx.hrStyle} />)}
                {additionalInfoExist && additionalSection}
            </AccordionDetails>
        </Accordion>
        <Dialog open={reportDlgOpen}>
            <DialogTitle>BEES 데이터 신고하기</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{p:1}}>
                    <Stack spacing={2} style={{minWidth: 480}}>
                    <TextField label="신고자" defaultValue={userInfo.email} InputProps={{readOnly:true}}></TextField>
                    <TextField label="대상" defaultValue={`${personInfo.name} [${candidateId}]`} InputProps={{readOnly:true}}></TextField>
                    <TextField label="내용" multiline rows={5} value={reportContents}
                        onChange={(e) => setReportContents(e.target.value)}></TextField>
                    </Stack>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setReportDlgOpen(false)}>취소</Button>
                <Button disabled={!reportContents} onClick={onReportDataError}>확인</Button>
            </DialogActions>
        </Dialog>
        </>
    );
}

export default PersonInfo;