import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {Tooltip} from "@mui/material";

function VerifiedIndicator({extrValue}) {
    const title = `BEES: ${extrValue}`
    return (<Tooltip title={title} placement="bottom-end"
                     slotProps={{popper: {modifiers: [{name:'offset', options: {offset: [0, -10]}}]}}}>
        <CheckCircleRoundedIcon style={{marginLeft:"2px"}} fontSize="inherit" color="success"/>
    </Tooltip>)
}

export default VerifiedIndicator;