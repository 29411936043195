import {apiWaggleGetCandidateArticles} from "../store/api_waggle";
import DataGridAg from "./dataGridAg/DataGridAg";
import {metaAtom} from "../store/atom";
import {useAtomValue} from "jotai";

function PersonReferencesNews({ candidateId }) {

    const meta = useAtomValue(metaAtom);

    const columnDefs = [{
        field: 'article_updated', title: '날짜', width: 70, type: 'string',
    }, {
        field: 'channel', title: '매체', width: 80, type: 'string',
        valueGetter: p => `${p.data.channel} / ${(p.data.article_type||'').split('(')[0].trim()}`,
    }, {
        field: 'title', title: '제목', flex: 1, type: 'text',
        valueGetter: p => ({'title': p.data.title, 'subTitle': p.data.sub_title, 'url': p.data.article_url}),
        cellRenderer: p => (<div>
                <div><a href={p.value.url} target="_blank" rel="noreferrer">{p.value.title}</a></div>
                <div style={{fontSize: '0.8125rem', color: '#666'}}>{p.value.subTitle}</div>
            </div>)
    }, {
        field: 'red_flag_tf', title: '⛔️️', width: 50, type: 'text', cellClass: 'dark', headerTooltip: "레드 플래그",
        cellRenderer: p => (<span style={{color:'#ff0000'}}>{p.value ? '✔': ''}</span>)
    }, {
        field: 'article_relevance_scale', title: '🚸', width: 50, type: 'text', cellClass: 'dark', headerTooltip: "기사 관련도",
        valueGetter: p => p.data.article_relevance_scale === 'Significant' ? 'S' :
            (p.data.article_relevance_scale === 'Moderate' ? 'M' : '')
    }, {
        field: 'article_keywords', title: '키워드', width: 120, type: 'text', cellClass: 'dark',
        valueFormatter: p => (p.value||[]).join(', ')
    }, {
        field: 'social_problem', title: '사회문제', width: 120, type: 'text', cellClass: 'dark',
        valueFormatter: p => (p.value||[]).join(', ')
    }, {
        field: 'solution', title: '솔루션', width: 120, type: 'text', cellClass: 'dark',
        valueFormatter: p => (p.value||[]).join(', ')
    }, {
        field: 'candidate_summary', title: '후보자 관련 요약', flex: 2, type: 'text', cellClass: 'dark',
    }, {
        field: 'article_summary', title: '요약', flex: 2, type: 'text', cellClass: 'dark',
    }];

    return (
        <DataGridAg
            queryKey={['waggle-candidate-articles', candidateId]}
            queryFn={() => apiWaggleGetCandidateArticles(candidateId)}
            queryEnabled={!!meta}
            columnDefs={columnDefs}
            config={{readOnly: true, getRowId: (params) => params.data.uuid}} />
    )
}

export default PersonReferencesNews;