import {apiWaggleGetCandidateHoneycombHistory} from "../store/api_waggle";
import {metaAtom} from "../store/atom";
import {useAtomValue} from "jotai";
import {Grid, Typography} from "@mui/material";
import {AgGridReact} from "ag-grid-react";
import React from "react";
import {useQuery} from "@tanstack/react-query";
import {utcDayjs} from "../util/misc";

const styles = {
    title: {fontWeight:600, marginBottom: 8}
}

function getHoneycombDocTypeName(docType) {
    switch (docType) {
        case 'application': return '지원서';
        case 'nomination': return '추천서';
        default: return `unknown(${docType})`;
    }

}

function PersonHoneycombHistory({ candidateId }) {

    const meta = useAtomValue(metaAtom);
    const {isSuccess, data} = useQuery({
        queryKey: ['waggle-candidate-honeycomb-history', candidateId],
        queryFn: () => apiWaggleGetCandidateHoneycombHistory(candidateId),
        enabled: !!meta,
    });

    if (!isSuccess) return null;
    if (!data) return null;

    const rowData = data?.history || [];

    const columnDefs = [{
        field: 'campaign_name', headerName: '사업명', width: 200,
    }, {
        field: 'doc_type', headerName: '문서유형', width: 100,
        valueFormatter: (params) => getHoneycombDocTypeName(params.value),
    }, {
        field: 'doc_url', headerName: '문서 링크', flex: 1,
        cellRenderer: (params) => {
            const url = params.data.doc_url;
            return <a href={url} target="_blank" rel="noreferrer">{url}</a>
        }
    }, {
        field: 'submitted_at', headerName: '제출일', width: 150,
        valueFormatter: (params) => utcDayjs(params.value).format('YY-MM-DD HH:mm'),
    }];

    return (
        <div>
            <Typography style={styles.title}>허니콤 이력이 존재합니다</Typography>
            <Grid container className="data-grid-ag-container no-min-height">
                <Grid item className="bf-form-hctable ag-theme-alpine" xs={12}>
                    <AgGridReact columnDefs={columnDefs} rowData={rowData} domLayout="autoHeight"
                        suppressRowHoverHighlight enableCellTextSelection
                        getRowId={(params) => params.data.doc_url} animateRows={false}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default PersonHoneycombHistory;