import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {apiWaggleGetCandidates, apiWaggleReportDataError, apiWaggleSetCandidateTrivial} from "../store/api_waggle";
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector, gridPaginationRowCountSelector,
    GridToolbarContainer,
    useGridApiContext,
    useGridSelector
} from "@mui/x-data-grid";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import ApplyBtnIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import ClearBtnIcon from '@mui/icons-material/CloseOutlined';
import {
    Box,
    Button, Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Switch,
    Tab,
    Tabs, Tooltip, Typography
} from "@mui/material";
import {useAtomValue, useSetAtom} from "jotai";
import {feedbackSnackbarAtom, metaAtom, userInfoAtom} from "../store/atom";
import {buildErrorMessage, utcDayjs} from "../util/misc";
import {objectToQueryString, useQueryParams} from "../util/query_params";
import MuiPagination from "@mui/material/Pagination";
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import ConfirmDialog from "../components/common/ConfirmDialog";
import {Helmet} from "react-helmet-async";

const sx = {
    searchCont: {margin: "16px 0px 10px 0"},
    totalRows: {fontSize: "0.875rem", fontWeight:500, color:"#847979", marginRight:2}
}

function WaggleCandidates(props) {

    const meta = useAtomValue(metaAtom);
    const userInfo = useAtomValue(userInfoAtom);
    const setFeedbackSnackbar = useSetAtom(feedbackSnackbarAtom);
    const navigate = useNavigate();
    const urlParams = useQueryParams();
    const PAGE_SIZE = 20;

    const [searchAssigned, setSearchAssigned] = useState(urlParams.get("assigned") === 'Y');
    const [searchRelatedBusiness, setSearchRelatedBusiness] = useState(urlParams.get("related_business") || '');
    const [searchPool, setSearchPool] = useState(urlParams.get("pool") || '');
    const [searchMyFeedbackOnly, setSearchMyFeedbackOnly] = useState(urlParams.get("my_feedback_only") === 'Y' || false);
    const [searchKeywords, setSearchKeywords] = useState(urlParams.get("keywords") || '');
    const [showTrivial, setShowTrivial] = useState(urlParams.get("show_trivial") === 'Y' || false);
    const [searchHoneycomb, setSearchHoneycomb] = useState(urlParams.get("honeycomb") === 'Y');
    const [query, setQuery] = useState(searchKeywords);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: PAGE_SIZE,
        page: parseInt(urlParams.get('page') || '1') - 1,
    });
    const [sortingModel, setSortingModel] = useState({
        field: urlParams.get('sort_key') || 'name',
        sort: urlParams.get('sort_order') || 'asc'
    });
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        trivial: urlParams.get("show_trivial") === 'Y',
    });
    const [confirmDlgContents, setConfirmDlgContents] = useState(null);

    const queryKey = ["waggle_candidate",
        searchKeywords, searchAssigned, searchRelatedBusiness, searchPool, searchMyFeedbackOnly, searchHoneycomb,
        showTrivial, paginationModel, sortingModel
    ];
    const queryClient = useQueryClient();
    const {isSuccess, data, isError, error, isLoading} = useQuery({
        queryKey: queryKey,
        queryFn: () => apiWaggleGetCandidates(
            searchKeywords, searchAssigned, searchRelatedBusiness, searchPool, searchMyFeedbackOnly, searchHoneycomb,
            showTrivial, paginationModel.page + 1, paginationModel.pageSize, sortingModel.field, sortingModel.sort),
        enabled: !!meta
    });
    const setTrivialMutation = useMutation({
        mutationFn: apiWaggleSetCandidateTrivial,
        onSuccess: (resData, variables, context) => {
            console.log('onSaveSuccess', data, variables, context);
            queryClient.invalidateQueries({queryKey}).then(r => {});
        },
        onError: (error, variables, context) => {
            console.log('onSaveError', error, variables, context);
            setFeedbackSnackbar([buildErrorMessage(error), 'error']);
        }
    });
    const reportDataErrorMutation = useMutation({
        mutationFn: apiWaggleReportDataError,
        onSuccess: (resData, variables, context) => {
            setFeedbackSnackbar(['신고가 접수되었습니다.', 'success']);
        },
        onError: (error, variables, context) => {
            setFeedbackSnackbar([buildErrorMessage(error), 'error']);
        }
    });

    const buildParams = () => {
        const params = {
            keywords: searchKeywords,
            assigned: searchAssigned ? 'Y' : '',
            related_business: searchRelatedBusiness,
            pool: searchPool,
            my_feedback_only: searchMyFeedbackOnly ? 'Y' : '',
            honeycomb: searchHoneycomb ? 'Y' : '',
            show_trivial: showTrivial ? 'Y' : '',
            page: paginationModel.page + 1,
            sort_key: sortingModel.field,
            sort_order: sortingModel.sort,
        }
        return objectToQueryString(params, true);
    }

    useEffect(() => {
        const url = `/waggle/candidates?${buildParams()}`;
        navigate(url, { replace: true });
    }, queryKey);

    const rowCountRef = useRef(data?.pageInfo?.total || 0);
    const rowCount = useMemo(() => {
        if (data?.pageInfo?.total !== undefined) {
            rowCountRef.current = data?.pageInfo?.total;
        }
        return rowCountRef.current;
    }, [data?.pageInfo?.total]);

    if (! meta) return null;

    const convertData = (candidates) => {
        const rows = [];
        candidates && candidates.forEach((d) => {
            const row = {uuid: d.uuid, num_feedbacks: d.num_feedbacks, num_news: d.num_news,
                num_books: d.num_books, num_facebook_posts: d.num_facebook_posts,
                num_youtube_contents: d.num_youtube_contents};
            const pseudonym = d.pseudonym || d.extr_pseudonym;
            const job = d.job || d.extr_job || [];
            const associations = d.associations || d.extr_associations || [];
            const keywords = d.keywords || d.extr_keywords || [];
            row['name'] = pseudonym ? `${d.name} (${pseudonym})` : d.name;
            row['job'] = (typeof job === 'string' ? [job] : job).slice(0, 3).join(', ');
            row['associations'] = (typeof associations === 'string' ? [associations] : associations).slice(0, 3).join(', ');
            row['keywords'] = keywords.slice(0, 3).join(', ');
            row['trivial'] = d.trivial || false;
            if (d.review && d.review.assignments) {
                let num_reviewed = 0, assigned_to_me = false, completed_my_review = false;
                d.review.assignments.forEach((a) => {
                    if (a.completed_at) num_reviewed++;
                    if (a.assignee === userInfo.email) {
                        assigned_to_me = true
                        if (a.completed_at) completed_my_review = true;
                    }
                });
                row['review'] = {num_assign: d.review.assignments.length, num_reviewed, assigned_to_me, completed_my_review};
            } else {
                row['review'] = {num_assign:0, num_reviewed:0, assigned_to_me: false};
            }
            row['updated_at'] = utcDayjs(d.updated_at).format('YY.MM.DD HH:mm');
            rows.push(row);
        });
        return rows;
    }

    const columns = [
        {field: "trivial", headerName: '', width: 10, minWidth: 10, sortable: false, valueGetter: (v) => v ? '✘' : ''},
        {field: "name", headerName: '이름', width: 90, sortingOrder: ['asc', 'desc'],
            renderCell: (p) => <a href={`/waggle/candidates/${p.row.uuid}`}>{p.row.name}</a>
        }, {
            field: "nums", headerName: '추가자료', width: 130, sortable: false,
            renderCell: (p) => {
                const sum = p.row.num_news + p.row.num_books + p.row.num_facebook_posts + p.row.num_youtube_contents;
                if (sum === 0) return null;
                const label = `n:${p.row.num_news} b:${p.row.num_books} f:${p.row.num_facebook_posts} y:${p.row.num_youtube_contents}`;
                return <Chip label={label} size="small" />;
            }
        },
        {field: "job", headerName: '직업', flex: 2, sortable: false,
            renderCell: (p) => (<Tooltip title={p.row.job}><span>{p.row.job}</span></Tooltip>)},
        {field: "associations", headerName: "소속", flex: 3, sortable: false,
            renderCell: (p) => (<Tooltip title={p.row.associations}><span>{p.row.associations}</span></Tooltip>)},
        {field: "keywords", headerName: "키워드", flex: 3, sortable: false,
            renderCell: (p) => (<Tooltip title={p.row.keywords}><span>{p.row.keywords}</span></Tooltip>)},
        {field: "review", headerName: "리뷰", width: 70, sortable: false,
            renderCell: (p) => {
                let color;
                if (p.row.review.assigned_to_me) {
                    color = p.row.review.completed_my_review ? 'success' : 'error';
                }
                return <Chip label={`${p.row.review.num_reviewed}/${p.row.review.num_assign}`} color={color} size="small" />;
            }
        }
        // {field: "updated_at", headerName: "최근 수정일", width: 120}
    ];

    const rows = convertData(data?.candidates || []);

    function CustomToolbar() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector) + 1;
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);
        const rowCount = useGridSelector(apiRef, gridPaginationRowCountSelector);

        const setTrivial = () => {
            setTrivialMutation.mutate({ids: rowSelectionModel, is_trivial: true});
        }

        const confirmReportSameCandidates = () => {
            const persons = rows.filter((r) => rowSelectionModel.includes(r.uuid));
            setConfirmDlgContents({
                title: "선택된 다음 항목을 동일인물로 신고합니다.",
                message: persons.map((p) => `${p.name} [${p.uuid}]`),
                cancel: true,
                onOk: () => {
                    reportDataErrorMutation.mutate({error_type:'same_person', candidate_ids: rowSelectionModel});
                    setConfirmDlgContents(null);
                    setRowSelectionModel([]);
                }
            })
        }

        return (
            <GridToolbarContainer>
                <Button disabled={rowSelectionModel===undefined || rowSelectionModel.length===0}
                        onClick={setTrivial}>선택된 항목 제외</Button>
                <FormControlLabel label="제외된 항목 보기"
                    control={
                        <Switch
                            size="small"
                            checked={showTrivial}
                            onChange={(event) => {
                                setShowTrivial(event.target.checked);
                                setColumnVisibilityModel({trivial: event.target.checked});
                            }}
                        />
                    }
                />
                <Button disabled={rowSelectionModel===undefined || rowSelectionModel.length<2}
                    onClick={confirmReportSameCandidates}>선택된 항목 동일인물 신고</Button>
                <Box sx={{ flexGrow: 1 }} />
                <Typography sx={sx.totalRows}>전체: {rowCount}개</Typography>
                <MuiPagination showFirstButton showLastButton count={pageCount} page={page}
                               onChange={(ev, page) => apiRef.current.setPage(page-1)} />
            </GridToolbarContainer>
        );
    }

    const resetPage = function() { setPaginationModel({page: 0, pageSize: 20}); }
    const onChangeKeywords = (keywords) => {resetPage();setSearchKeywords(keywords);}
    const onChangeRelatedBusiness = (e) => {resetPage();setSearchRelatedBusiness(e.target.value);}
    const onChangePool = (e) => {resetPage();setSearchPool(e.target.value);}
    const onChangeMyFeedbackOnly = (e) => {resetPage();setSearchMyFeedbackOnly(e.target.checked);}
    const onChangeHoneycombOnly = (e) => {resetPage();setSearchHoneycomb(e.target.checked);}
    const resetSearch = () => {
        resetPage();
        setSearchKeywords('');
        setSearchRelatedBusiness('');
        setSearchPool('');
        setSearchMyFeedbackOnly(false);
        setQuery('');
        queryClient.invalidateQueries({queryKey}).then(r => {});
    }
    const onChangeSortingModel = (sortModel) => {
        if (sortModel.length === 0) return;
        setSortingModel(sortModel[0]);
    }
    const onChangeRowSelectionModel = (newSelection) => {
        console.log(newSelection);
        setRowSelectionModel(newSelection);
    }

    return (<>
    <Helmet>
        <title>후보 목록 | BAT</title>
    </Helmet>
    <div className="bat-main-container">
        <Tabs value={searchAssigned ? "me":"all"} onChange={(ev, newValue) =>setSearchAssigned(newValue === "me")}>
            <Tab label="전체 목록" value="all"/>
            <Tab label="나에게 할당된 목록" value="me"/>
        </Tabs>
        <Box>
            {/*<Paper sx={sx.searchCont}>*/}
                <Stack sx={sx.searchCont} direction="row" spacing={1}>
                    <FormControl size="small">
                        <InputLabel htmlFor="search-keyword-input">검색어</InputLabel>
                        <OutlinedInput id="search-keyword-input" value={query} label="검색어"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') onChangeKeywords(query);
                                if (e.key === 'Escape') setQuery('');
                            }}
                            onChange={(e) => setQuery(e.target.value)}
                            endAdornment={<InputAdornment position="end"><IconButton edge="end"
                                onClick={() => {query === searchKeywords ? setQuery('') : onChangeKeywords(query);}}>
                                {query && query === searchKeywords ? <ClearBtnIcon/> : <ApplyBtnIcon/>}
                            </IconButton></InputAdornment>}
                        />
                    </FormControl>
                    <FormControl size="small" style={{marginLeft:32}}>
                        <InputLabel htmlFor="search-related-business">관련 사업</InputLabel>
                        <Select id="search-related-business" label='관련 사업' sx={{width: 180}}
                                value={searchRelatedBusiness} onChange={onChangeRelatedBusiness}>
                            <MenuItem key='all' value={''}>전체</MenuItem>
                            {(meta?.domain.waggle.bi_business || []).map(
                                (option) => <MenuItem key={option.id} value={option.id}>{option.title}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl size="small">
                        <InputLabel htmlFor="search-pool">가능 관계</InputLabel>
                        <Select id="search-pool" label='가능 관계' sx={{width: 180}}
                                value={searchPool} onChange={onChangePool}>
                            <MenuItem key='all' value={''}>전체</MenuItem>
                            {(meta?.domain.waggle.bi_candidate_roles || []).map(
                                (option) => <MenuItem key={option.id} value={option.id}>{option.title}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControlLabel size="small" label="내 리뷰만" control={
                        <Checkbox checked={searchMyFeedbackOnly} onChange={onChangeMyFeedbackOnly} sx={{padding: "5px"}}/>
                    }/>
                    <FormControlLabel size="small" label="허니콤" control={
                        <Checkbox checked={searchHoneycomb} onChange={onChangeHoneycombOnly} sx={{padding: "5px"}}/>
                    }/>
                    <IconButton style={{marginLeft:32}} onClick={resetSearch}><SearchOffRoundedIcon /></IconButton>
                </Stack>
            {/*</Paper>*/}
            <DataGrid
                initialState={{sorting: {sortModel: [sortingModel]}}}
                columns={columns}
                loading={isLoading} autoHeight density="compact" disableColumnMenu hideFooter
                getRowId={(row) => row.uuid}
                rows={rows}
                rowCount={rowCount}
                paginationMode={'server'}
                pageSizeOptions={[20]}
                paginationModel={paginationModel}
                onPaginationModelChange={(pageInfo) => setPaginationModel(pageInfo)}
                sortingMode="server"
                onSortModelChange={(sortModel) => onChangeSortingModel(sortModel)}
                checkboxSelection={!showTrivial}
                disableRowSelectionOnClick
                rowSelectionModel={rowSelectionModel}
                onRowSelectionModelChange={(newSelection) => onChangeRowSelectionModel(newSelection)}
                columnVisibilityModel={columnVisibilityModel}
                slots={{toolbar: CustomToolbar}}
            />
            <ConfirmDialog open={!!confirmDlgContents} title={confirmDlgContents?.title}
                           message={confirmDlgContents?.message} cancelExist={confirmDlgContents?.cancel}
                           onClose={() => setConfirmDlgContents(null)}
                           onOk={confirmDlgContents?.onOk}
            />
        </Box>
    </div>
    </>);
}

export default WaggleCandidates;