import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {isValidUrl} from "../../util/misc";
import StyledTextArea from "../common/StyleTextAreaAutoSize";
import MultiSelect from "../common/MultiSelect";

/*
.hc-table-edit .dlg-desc {margin-bottom: 10px;}
.hc-table-edit .col-container {margin-bottom: 20px;}
.hc-table-edit .col-title {font-weight:500; font-size: 1rem; margin-bottom: 4px;}
.hc-table-edit .col-desc {font-size: 0.8125rem; margin-bottom: 6px;}
 */
const sx = {
    colContainer: {marginBottom: 8},
    colTitle: {fontWeight: 500, fontSize: '1rem', marginBottom: 4},
    colDesc: {fontSize: '0.8125rem', marginBottom: 6},
}

function convertFieldToDialog(source, targetObj, key, fieldType) {
    switch (fieldType) {
        case 'date':
            targetObj[key] = dayjs(source, 'YYYY.MM.DD');
            break;
        case 'date_yyyymm':
            targetObj[key] = dayjs(source, 'YYYY.MM');
            break;
        case 'date_period_yyyymm':
            const [from, to, current] = source.split(',');
            targetObj[key] = {
                from: dayjs(from, 'YYYY.MM'),
                to: dayjs(to, 'YYYY.MM'),
                current: current === 'Y'
            }
            //console.log('convertFieldToDialog', source, targetObj[key], from, to);
            break;
        default:
            targetObj[key] = source === undefined ? '' : source;
    }
}

function convertFieldToStore(source, targetObj, key, fieldType) {
    switch (fieldType) {
        case 'date':
            targetObj[key] = source.format('YYYY.MM.DD');
            break;
        case 'date_yyyymm':
            targetObj[key] = source.format('YYYY.MM');
            break;
        case 'date_period_yyyymm':
            const to = source.current ? '' : source.to.format('YYYY.MM');
            targetObj[key] = `${source.from.format('YYYY.MM')},${to},${source.current ? 'Y' : 'N'}`;
            break;
        default:
            targetObj[key] = source;
    }
}

function verifyField(source, key, fieldType, mandatory) {
    console.log('[verifyField]', source, key, fieldType, mandatory);
    let errorMessage = null;
    switch (fieldType) {
        case 'date':
            if (!dayjs(source, 'YYYY.MM.DD').isValid())
                errorMessage = '날짜 형식이 올바르지 않습니다.';
            break;
        case 'date_yyyymm':
            if (!dayjs(source, 'YYYY.MM').isValid())
                errorMessage = '날짜 형식이 올바르지 않습니다.';
            break;
        case 'date_period_yyyymm':
            const [from, to, current] = source.split(',');
            const fromDayjs = dayjs(from, 'YYYY.MM'), toDayjs = dayjs(to, 'YYYY.MM');
            if (!(dayjs(from, 'YYYY.MM').isValid() && (current === 'Y' || dayjs(to, 'YYYY.MM').isValid())
                && (!toDayjs.isBefore(fromDayjs))))
                errorMessage = '입력 값에 오류가 있습니다.';
            break;
        case 'url':
            if (!isValidUrl(source))
                errorMessage = 'URL 형식이 올바르지 않습니다.("http://" 혹은 "https://"를 포함한 전체 주소를 적어주세요)';
            break;
        default:
    }
    if (mandatory && !source) {
        errorMessage = '필수 입력 항목입니다.';
    }
    return errorMessage;
}

/**
 * HCTableEditDialog
 * @param initValue - 초기값 (field dict)
 * @param defaultValue - 초기값이 없을 경우 기본값 (field dict)
 * @param columnDefs - DataGridAg ColumnDef
 * @param onSave - 저장 버튼 클릭 시 호출되는 함수
 * @param onCancel - 취소 버튼 클릭 시 호출되는 함수
 * @param title - Dialog 제목
 * @param props - Dialog에 전달되는 속성
 * @returns {React.JSX.Element|null}
 * @constructor
 */
/* 사용하는 columnDefs
 - field
 - type
 - title
 - desc
 - options.mandatory: verification에서 사용
 - options.hidden: true이거나 함수(param=value)의 값이 true일 경우 dialog에서 보이지 않음
 - options.notEditable: true이거나 함수(param=value)의 값이 true일 경우 수정 불가능
 */

function HCTableEditDialog({
    initValue, defaultValue, columnDefs, onSave, onCancel, title, ...props
}) {
    const columns = columnDefs;
    const [itemValue, setItemValue] = React.useState(defaultValue);
    const [itemValueError, setItemValueError] = React.useState({});

    useEffect(() => {
        const newItemValue = {};
        const newError = {}
        columns.forEach((column) => {
            const {field, type} = column;
            convertFieldToDialog(initValue?.[field] || defaultValue[field], newItemValue, field, type);
            newError[field] = false;
        });
        setItemValue(newItemValue);
        setItemValueError(newError);
        //console.log('[HCTableEditDialog]', newItemValue);
    }, [initValue]);

    function onSubmit(data) {
        const newStoreValue = {};
        const newError = {};
        columns.forEach((column) => {
            const {field, type} = column;
            const {mandatory} = (column.options || {});
            convertFieldToStore(itemValue[field], newStoreValue, field, type);
            newError[field] = verifyField(newStoreValue[field], field, type, mandatory);
        });
        if (Object.values(newError).some(o => o)) {
            setItemValueError(newError);
        } else {
            const saveData = {...initValue, ...newStoreValue};
            console.log('onSubmit', saveData);
            onSave(saveData);
        }
    }

    function setField(newValue, secondKey, field, type) {
        if (secondKey) {
            setItemValue({...itemValue, [field]: {...itemValue[field], [secondKey]: newValue}});
        } else {
            setItemValue({...itemValue, [field]: newValue});
        }
    }


    function getField(column) {
        const {type, field} = column;
        switch (column.type) {
            case 'string':
            case 'url':
            case 'defined':
                return <OutlinedInput
                    fullWidth size="small"
                    value={itemValue[field]}
                    onChange={(e) => setField(e.target.value, null, field, type)}
                    name={field}
                    disabled={column.type === 'defined'}
                ></OutlinedInput>
            case 'text':
                return <StyledTextArea
                    value={itemValue[field]}
                    onChange={(e) => setField(e.target.value, null, field, type)}
                    minRows={2}
                    maxRows={5}
                    disabled={column.type === 'defined'}
                ></StyledTextArea>
            case 'date':
                return <DatePicker value={itemValue[field]} format="YYYY. MM. DD"
                    onChange={(newValue) => setField(newValue, null, field, type)} />
            case 'date_yyyymm':
                return <DatePicker views={['month', 'year']} format="YYYY. MM." value={itemValue[field]}
                    onChange={(newValue) => setField(newValue, null, field, type)} />
            case 'date_period_yyyymm':
                return <Stack direction="row" spacing={1} alignItems="center">
                    <DatePicker views={['month', 'year']} format="YYYY. MM."
                                value={itemValue[field]['from']}
                                onChange={(newValue) => setField(newValue, 'from', field, type)} />
                    <Typography>~</Typography>
                    {!itemValue[field]['current'] && <DatePicker views={['month', 'year']} format="YYYY. MM."
                                value={itemValue[field]['to']} minDate={itemValue[field]['from']}
                                onChange={(newValue) => setField(newValue, 'to', field, type)} />}
                    <FormControlLabel sx={{width:120}} label="현재" control={
                        <Checkbox checked={itemValue[field]['current']} size="small"
                                  onChange={(e) => setField(e.target.checked, 'current', field, type)} />} />
                </Stack>
            case'select':
                return (<FormControl fullWidth>
                    <Select value={itemValue[field]} size="small"
                        onChange={(event) => setField(event.target.value, null, field, type)}
                    >{column.options.selectOptions.map(
                        (option) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
                    </Select>
                </FormControl>);
            case 'multiselect':
                return (<MultiSelect value={itemValue[field]}
                                     onChange={(newValue) => setField(newValue, null, field, type)}
                                     config={{selectMinWidth: column.options.selectMinWidth}}
                                     options={column.options.selectOptions}/>);
            case 'free_solo':
                return (<Autocomplete size="small" freeSolo
                                      value={itemValue[field]}
                                      onChange={(e, item) => setField(item ? item.value : '', null, field, type)}
                                      options={column.options.selectOptions}
                                      renderInput={(params) => <TextField {...params} />}/>)
            default:
                return <span>{itemValue[field]}</span>;
        }
    }

    if (!initValue) return null;

    return (
        <Dialog fullWidth className="hc-table-edit" {...props}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                {columns.map((col, idx) => {
                    const hidden = col?.options?.hidden;
                    if (typeof hidden === 'function') { if (hidden(itemValue[col.field])) return null; }
                    else if (hidden) return null;
                    return <div key={idx} style={sx.colContainer}>
                        <div style={sx.colTitle}>{col.title}{col.options.mandatory && <span style={{color: 'red'}}>*</span>}</div>
                        {col.desc && <div style={sx.colDesc}>{col.desc}</div>}
                        {getField(col)}
                        {itemValueError[col.field] && <div><Typography variant="caption" color="red">{itemValueError[col.field]}</Typography></div>}
                    </div>
                })}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="hc_contained_navy" onClick={onCancel}>취소</Button>
                <Button type="submit" variant="hc_contained_navy" onClick={onSubmit}>저장</Button>
            </DialogActions>
        </Dialog>
    )
}

export default HCTableEditDialog