import {API_BASE_URL, build_default_headers, check_api_response, fetchWithToken} from "./api_common";

const apiWaggleGetCandidates = async(searchWords, assignedOnly, relatedBusiness, pool, myFeedbackOnly, honeycombOnly,
                                     showTrivial, page, perPage, sortKey, sortOrder) => {
    const params = {
        page: page, per_page: perPage,
        search_words: searchWords,
        assigned_only: assignedOnly, related_business: relatedBusiness, pool: pool, my_feedback_only: myFeedbackOnly,
        honeycomb_only: honeycombOnly,
        show_trivial: showTrivial,
        sort_key: sortKey, sort_order: sortOrder,
    };
    console.log('[API] fetch candidates', params);
    const queryString = new URLSearchParams(params).toString();
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates?${queryString}`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return {'candidates': json.candidates, 'pageInfo': json.page_info};
}

const apiWaggleGetCandidateInfo = async(candidateId) => {
    console.log('[API] fetch candidate info', candidateId);
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/${candidateId}/info`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json.info;
}

const apiWaggleGetCandidateReferenceResourceCounts = async(candidateId) => {
    console.log('[API] fetch candidate reference resource counts', candidateId);
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/${candidateId}/reference-resource-counts`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json.counts;
}

const apiWaggleGetCandidateFeedbacks = async(candidateId) => {
    console.log('[API] fetch candidate feedbacks', candidateId);
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/${candidateId}/feedbacks`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json.feedbacks;

}

const apiWaggleUpdateCandidateInfoField = async(params) => {
    const {candidateId, field, value} = params;
    console.log('[API] update candidate info field', candidateId, field, value);
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/${candidateId}/update-info`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify({candidate_id: candidateId, field, value}),
    });
    const json = await check_api_response(response);
    return json;
}

const apiWaggleUpsertCandidateFeedback = async(params) => {
    const {uid, writer} = params;
    console.log('[API] upsert candidate feedback', uid, writer);
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/${uid}/upsert-feedback`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(params),
    });
    const json = await check_api_response(response);
    return json;
}

const apiWaggleDeleteCandidateFeedback = async(params) => {
    const {feedback_id, uid} = params;
    console.log('[API] delete candidate feedback', feedback_id);
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/${uid}/delete-feedback`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(params)
    });
    const json = await check_api_response(response);
    return json;
}

const apiWaggleSetCandidateTrivial = async(params) => {
    const {ids, is_trivial} = params;
    console.log('[API] set candidate trivia', ids, is_trivial);
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/set-trivial`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(params),
    });
    const json = await check_api_response(response);
    return json;
}

const apiWaggleReportDataError = async(params) => {
    const {error_type, candidate_ids, comment} = params;
    console.log('[API] report data error', error_type, candidate_ids);
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/report-waggle-data-error`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify({error_type, candidate_ids, comment}),
    });
    const json = await check_api_response(response);
    return json;
}

const apiWaggleUpdateReviewAssign = async(params) => {
    const {candidate_id, assignee, operation} = params;
    console.log('[API] update review assign', candidate_id, assignee, operation);
    const body = {candidate_id, assignee, operation};
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/${candidate_id}/update-review-assignment`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify(body),
    });
    const json = await check_api_response(response);
    return json;
}

const apiWaggleGetNextReview = async(candidateId) => {
    console.log('[API] fetch next review');
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/${candidateId}/review-next`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json;
}

const apiWaggleGetCandidateArticles = async(candidateId) => {
    console.log('[API] fetch candidate articles', candidateId);
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/${candidateId}/articles`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json.articles;
}

const apiWaggleGetCandidateBooks = async(candidateId) => {
    console.log('[API] fetch candidate books', candidateId);
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/${candidateId}/books`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json.books;
}

const apiWaggleGetCandidateFacebookPosts = async(candidateId) => {
    console.log('[API] fetch candidate facebook', candidateId);
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/${candidateId}/facebook_posts`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json.facebook_posts;
}

const apiWaggleGetCandidateYoutube = async(candidateId) => {
    console.log('[API] fetch candidate youtube', candidateId);
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/${candidateId}/youtube`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json.youtube;
}

const apiWaggleGetCandidateBeesEamReport = async(candidateId) => {
    console.log('[API] fetch candidate bees ead', candidateId);
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/${candidateId}/bees-eam-report`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json.bees_eam_report;
}

const apiWaggleGetCandidateHoneycombHistory = async(candidateId) => {
    console.log('[API] fetch candidate honeycomb history', candidateId);
    const response = await fetchWithToken(`${API_BASE_URL}/waggle/candidates/${candidateId}/honeycomb-history`, {
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json.history;
}

export {
    apiWaggleGetCandidates,
    apiWaggleGetCandidateInfo,
    apiWaggleGetCandidateReferenceResourceCounts,
    apiWaggleGetCandidateFeedbacks,
    apiWaggleUpdateCandidateInfoField,
    apiWaggleUpsertCandidateFeedback,
    apiWaggleDeleteCandidateFeedback,
    apiWaggleSetCandidateTrivial,
    apiWaggleReportDataError,
    apiWaggleUpdateReviewAssign,
    apiWaggleGetNextReview,
    apiWaggleGetCandidateArticles,
    apiWaggleGetCandidateBooks,
    apiWaggleGetCandidateFacebookPosts,
    apiWaggleGetCandidateYoutube,
    apiWaggleGetCandidateBeesEamReport,
    apiWaggleGetCandidateHoneycombHistory,
}