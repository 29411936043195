import {getTokenCookie, setTokenCookie} from "./cookies";
import {API_BASE_URL, ApiError, build_default_headers, check_api_response, fetchWithToken} from "./api_common";

const apiConnect = async () => {
    const response = await fetchWithToken(`${API_BASE_URL}/auth/connect`, {
        method: 'POST',
        headers: build_default_headers(),
    });
    const json = await check_api_response(response);
    return json;
}

const apiLogin = async (auth_code) => {
    const response = await fetch(`${API_BASE_URL}/auth/google`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify({auth_code, redirect_uri: window.location.origin})
    });
    const json = await check_api_response(response);
    const { token, refresh_token } = json;
    setTokenCookie(token, 'token');
    setTokenCookie(refresh_token, 'refresh_token');
}

const apiRefreshToken = async () => {
    const refreshToken = getTokenCookie('refresh_token');
    if (! refreshToken) {
        throw new ApiError('Refresh token failed', {status: 401});
    }
    const response = await fetch(`${API_BASE_URL}/auth/google-refresh`, {
        method: 'POST',
        headers: build_default_headers(),
        body: JSON.stringify({refresh_token: refreshToken}),
    });
    const json = await check_api_response(response);
    const { token } = json;
    return token;
}

export {
    apiConnect,
    apiLogin,
    apiRefreshToken,
}